
import CustomerGroup from '@/mixins/CustomerGroup';
import { mixins } from 'vue-class-component';
import { Component, Emit, Watch } from 'vue-property-decorator';
import { Range } from '@/interfaces/components/Range';
import { OrderFlavor } from '@/enums/OrderFlovor';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '@/mixins/StackedForm';
import { namespace } from 'vuex-class';
import { User } from '@/interfaces/models/User';
import { Venue } from '@/interfaces/models/Venue';

const auth = namespace('auth');
const venue = namespace('venue');

@Component({
  components: { VFormBuilder },
})
export default class SalesOverviewFilter extends mixins(CustomerGroup, StackedForm) {
  @auth.State('user') public authUser!: User;
  @venue.State('active') public venue!: Venue;

  public $refs!: {
    customerGroupForm: InstanceType<typeof VFormBuilder> & { form: any };
    dateForm: InstanceType<typeof VFormBuilder> & { form: any };
  };
  public customerGroup: string | null = null;
  public range: Range = {
    from: '',
    to: '',
  };

  get accessibleCustomerGroups() {
    if (this.$isAdmin()) {
      return this.customerGroups;
    }

    return this.authUser.customerGroup
      ? [this.authUser.customerGroup]
      : this.venue?.customerGroup
      ? [this.venue.customerGroup]
      : [];
  }

  get customerGroupItems() {
    return [
      {
        name: 'customerGroup',
        type: InputType.Autocomplete,
        label: 'billing.form.customerGroup',
        items: this.accessibleCustomerGroups,
        default: null,
        rules: 'required',
        change: (value: string) => (this.customerGroup = value),
      },
    ];
  }

  get dateItems() {
    return [
      {
        name: 'range',
        type: InputType.DateRangeOneField,
        label: 'billing.form.range',
        rules: 'required',
        showTimeSelector: false,
        change: (range: { from: string; to: string }) => (this.range = range),
      },
    ];
  }

  get selection() {
    return {
      customerGroup: this.customerGroup,
      ...this.range,
    };
  }

  public mounted() {
    if (!this.venue) {
      return;
    }

    this.$nextTick(() => {
      this.setDefaultCustomerGroup();
    });
  }

  public setDefaultCustomerGroup() {
    if (this.authUser.customerGroup) {
      this.$refs.customerGroupForm.setValue('customerGroup', this.authUser.customerGroup || null);
      this.customerGroup = this.authUser.customerGroup;
    } else if (this.venue.customerGroup) {
      this.$refs.customerGroupForm.setValue('customerGroup', this.venue.customerGroup || null);
      this.customerGroup = this.venue.customerGroup;
    }
  }

  public async validateFilter() {
    const results = await Promise.all(await this.validateWithKeys());
    const isValidWithKey: any = this.isValidWithKey(results);
    return isValidWithKey;
  }

  @Watch('venue')
  public async onVenueChange() {
    if (!this.venue?._id) {
      return;
    }

    this.setDefaultCustomerGroup();
  }

  @Watch('selection')
  @Emit('search')
  public onSearch() {
    return this.selection;
  }

  @Emit('change')
  public reset() {
    return this.selection;
  }
}
