import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { CustomerGroup } from '@/enums/CustomerGroup';

export default class ExportService extends ApiService<any> {
  constructor() {
    super('export', 'v1');
  }

  public downloadSales(param: { file: string }): AxiosPromise {
    return axios.get(`${this.getBaseUrl()}/sales/${param.file}`, { responseType: 'blob' });
  }

  public exportCodes(param: { customerGroups: string[]; format: 'csv' | 'xlsx' | 'zip' }): AxiosPromise<any> {
    let query: string = '';
    if (param && Object.keys(param).length > 0) {
      query = this.mapToQuery(param);
    }

    return axios.get(`${this.getBaseUrl()}/cup/?${query}`, { responseType: 'arraybuffer' });
  }

  public exportActivityLog(param: { venue: string; format: string; from: string; to: string }): AxiosPromise<any> {
    let query: string = '';
    if (param && Object.keys(param).length > 0) {
      query = this.mapToQuery(param);
    }

    return axios.get(`${this.version}/foodcard-log/export/logs/update?${query}`, { responseType: 'blob' });
  }

  public datevCsv(param: {
    from: string;
    to: string;
    venues: string[];
    customerGroups: CustomerGroup[];
  }): AxiosPromise {
    return axios.get(`${this.getBaseUrl()}/datev`, {
      params: param,
    });
  }

  public foodspotCsv(param: {
    from: string;
    to: string;
    venue?: string | string[];
    flavor: string;
    lang?: string;
  }): AxiosPromise {
    return axios.get(`${this.getBaseUrl()}/${param.venue}/csv`, { params: param });
  }
}
