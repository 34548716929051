export default {
  common: {
    edit: 'Modifier',
    yes: 'Oui',
    no: 'Non',
    back: 'Annuler',
    search: 'Chercher',
    none: 'Rien',
    save: 'Sauvegarder',
    reset: 'Régler',
    cancel: 'Annuler',
    next: 'Continuer',
    error: 'Erreur',
    selectHeaders: 'Select visible headers',
    weekday: 'Jour de la semaine',
    from: 'de',
    delete: 'Effacer',
    to: 'á',
    ok: 'Ok',
    all: 'Tous',
    single: 'Individuels',
    add: 'Ajouter',
    remove: 'Effacer',
    empty: 'Cette page est acuelement vide.',
    selectToImport: 'Bitte eine Datei zum Importieren auswählen',
    import: 'Import',
    export: 'Exporter',
    file: 'Fichier',
    apply: 'Appliquer',
    filters: 'Filtre',
    orders: 'Commandes',
    overview: "Vue d'ensemble",
    others: 'Autre	',
    skip: 'Sauter',
    time: 'Temp',
    date: 'Date',
    register: 'Enregistrer',
    submit: 'Confirmer',
    confirmDelete: 'Êtes-vous sûr de vouloir supprimer cet élément de manière irrévocable?',
    form: {
      file: 'Fichier',
    },
    selectItems: 'Veuillez sélectionner au moins un élément.',
  },
  languageField: {
    add: 'Ajouter',
    picker: 'Langue',
  },
  countries: {
    de: 'Allemagne',
    au: 'Autriche',
    fr: 'France',
    lu: 'Luxembourg',
    cz: 'Czech Republic',
  },
  toolbar: {
    selectOrSearchVenue: 'Sélectionner ou rechercher un restaurant...',
    noSelection: "Aucun restaurant n'a été choisi.",
  },
  login: {
    caption: 'Bienvenue à Smoothr!',
  },
  dashboard: {
    index: 'Dashboard',
    totalSum: 'CA total',
    totalVenues: 'Statistiques de {total} restaurants',
    totalNetSum: 'CA net total',
    orderSum: 'Nombre de commandes',
    table: {
      numberOfOrders: 'Nombre de commandes',
      venue: 'Restaurant',
      readableId: 'Short ID',
    },
  },
  uiBanner: {
    index: 'Banner',
    overview: 'Overview',
    general: 'Details',
    add: 'Create',
    create: 'Create',
    edit: 'Edit',
    empty:
      // tslint:disable-next-line:max-line-length
      "It looks like you haven't added any UI Banners yet. Create your first UI Banner now!",
  },
  uiCard: {
    index: 'Card',
    overview: 'Overview',
    general: 'Details',
    add: 'Create',
    create: 'Create',
    edit: 'Edit',
    empty:
      // tslint:disable-next-line:max-line-length
      "It looks like you haven't added any UI Cards yet. Create your first UI Card now!",
  },
  uiSlider: {
    index: 'Slider',
    overview: 'Overview',
    general: 'Details',
    add: 'Create',
    create: 'Create',
    edit: 'Edit',
    empty:
      // tslint:disable-next-line:max-line-length
      "It looks like you haven't added any UI Sliders yet. Create your first UI Slider now!",
  },
  notification: {
    form: 'Veuillez remplir tous les cadres.',
    400: "La demande n'a pas pu être traitée.",
    401: "Vos données d'accès ne sont pas correctes.",
    422: "Les cadres n'ont pas été remplis correctement. Si vous avez des questions, contacter notre service d'assistance.",
    404: "L'élément n'a pas pu être trouvé",
    403: 'Vous ne disposez pas des droits nécessaires pour voire la page.',
    500: 'Il y a un problème de serveur temporaire.',
    unknown: 'Erreur inconnue',
  },
  gender: {
    male: 'Masculin',
    female: 'Feminine',
    other: 'Autre',
  },
  displayModes: {
    default: 'Default',
    launcher: 'Launcher',
    smartPicker: 'Smart Picker',
    detailOverlay: 'Detail Overlay',
    detailDialog: 'Detail Dialog',
    grid: 'Grid',
    pagedGrid: 'Paged Grid',
    circle: 'Circle',
    topSeller: 'Top Seller',
    matrix: 'Matrix',
    banner: 'Banner',
    drinkFlavor: 'Drink Flavor',
    toggleButton: 'Toggle Button',
    flavor: 'Flavor',
    checkbox: 'Checkbox',
    radio: 'Radio',
    code: 'Code',
  },
  displayIdentifiers: {
    extra: 'Extra',
    extra15: 'Extra 15',
    extra30: 'Extra 30',
    extraCheese15: 'Extra Fromage 15',
    extraCheese30: 'Extra Fromage 30',
    size: 'Longeur',
    size15: 'Longeur 15',
    size30: 'Longeur 30',
    cheese: 'Fromage',
    kids: 'Enfants',
    salad: 'Salade',
    bread: 'Pain',
    cookie1: '1x Cookie',
    cookie3: '3x Cookie',
    cookie12: '12x Cookie',
    chips: 'Chips',
    sauce: 'Sauce',
    drink: 'Boisson',
    water: 'Eau',
    tea: 'Tée',
    hotBeverages: 'Boissons chaudes',
    menu: 'Formule',
    main: 'Main',
    sub: 'Sub',
    wrap: 'Wrap',
    sides: 'Supplément',
    fullPrice: 'Full Price',
    inverse: 'Inverse',
    code: 'Code',
  },
  venueSelector: {
    title: 'Sélectionnez un restaurant',
    search: 'Chercher un restaurant',
  },
  currency: {
    euro: 'Euro',
    chf: 'Schweizer Franken',
  },
  routes: {
    venue: {
      index: 'Restaurant',
      create: 'Créer un restaurant',
      createSimple: 'Créer un restaurant',
      edit: 'Éditer',
      panic: 'Suspendre',
      editSimple: 'Éditer',
      verify: 'Vérifier le restaurant',
      publish: 'Publier le restaurant',
    },
    payment: {
      index: 'Paiement',
    },
    home: {
      index: 'Home',
    },
    register: {
      index: 'Inscription',
      venue: 'Inscrire des restaurants',
      success: 'Inscription réussi',
    },
    analytics: {
      index: 'Analytics',
    },
    sales: {
      index: 'Ventes',
    },
    sepa: {
      index: 'Paramètres SEPA',
    },
    statistics: {
      index: 'Statistiques',
    },
    promo: {
      index: 'Codes promotionnels',
      create: 'Ajouter Code Promotionnnell',
      edit: 'Modifier Code Promotionnnell',
      export: 'Exporter Code Promotionnnell',
      disable: 'Désactiver Code Promotionnnell',
      createMultiple: 'Ajouter Plusierus Codes Individuelles',
    },
    billing: {
      index: 'Rapports',
    },
    userExport: {
      index: 'User export',
    },
    error: {
      index: 'Erreur',
      notFound: '404 Page Not Found',
      forbidden: '401 Forbidden',
    },
    user: {
      index: 'Utilisateur',
      create: 'Créer un utilisateur',
      edit: 'Modifier un utilisateur',
      createSimple: 'Créer un utilisateur',
      editSimple: "Editer l'utilisateur",
    },
    role: {
      index: 'Rôle',
      create: 'Créer un rôle',
      edit: 'Modifier un rôle',
    },
    foodcard: {
      index: "Importation d'un menu",
    },
    printer: {
      index: 'Imprimante',
      edit: 'Modifier Imprimeur',
    },
    tag: {
      index: 'Tag',
      create: 'Create Tag',
      edit: 'Edit Tag',
    },
    constrain: {
      index: 'Tradegroup',
      create: 'Create Tradegroup',
      edit: 'Edit Tradegroup',
    },
    printGroups: {
      index: 'Printgroups',
      create: 'Create Printgroups',
      edit: 'Edit Printgroups',
    },
    dashboard: {
      index: 'Dashboard',
    },
    table: {
      index: 'Tableau',
      create: 'Créer un tableau',
      edit: 'Modifier le tableau',
    },
    category: {
      index: 'Catégorie',
      create: 'Créer une catégorie',
      edit: 'Éditer la catégorie',
    },
    article: {
      index: 'Articles',
      create: 'Créer un article',
      edit: "Modifier l'article",
      editSimple: "Modifier l'article",
    },
    option: {
      index: 'Option',
      create: 'Option erstellen',
      edit: 'Option bearbeiten',
      editSimple: 'Option bearbeiten',
    },
    workflow: {
      index: 'Workflow',
      create: 'Create Workflow',
      upload: 'Upload Workflow',
      edit: 'Edit Workflow',
      import: 'Import Workflow',
    },
    customer: {
      index: 'Customer',
      create: 'Create Customer',
      edit: 'Edit Customer',
      import: 'Import Customer',
    },
    uiBanner: {
      index: 'Banners',
      create: 'Create Banner',
      edit: 'Edit Banner',
    },
    uiCard: {
      index: 'Cards',
      create: 'Create Card',
      edit: 'Edit Card',
    },
    uiSlider: {
      index: 'Sliders',
      create: 'Create Slider',
      edit: 'Edit Slider',
    },
    forgotPassword: 'Oublié votre mot de passe ?',
    resetPassword: 'Réinitialiser le mot de passe',
  },
  weekdays: {
    monday: 'Lundi',
    mondayShort: 'Lun',
    tuesday: 'Mardi',
    tuesdayShort: 'Ma',
    wednesday: 'Mercredi',
    wednesdayShort: 'Mec',
    thursday: 'Jeudi',
    thursdayShort: 'Jeu',
    friday: 'Vendredi',
    fridayShort: 'Ven',
    saturday: 'Samedi',
    saturdayShort: 'Sa',
    sunday: 'Dimanche',
    sundayShort: 'Di',
  },
  orderStatus: {
    none: 'Default',
    created: 'Créé',
    in_preparation: 'En préparation',
    ready: 'Prêt',
    in_payment: 'En paiement',
    awaiting_confirmation: 'En attente de confirmation',
    done: 'Fait',
  },
  mainCategories: {
    dish: 'Essen',
    beverage: 'Getränk',
    other: 'Other',
  },
  autoReset: {
    none: 'Aucun',
    morning: 'Matin',
    noon: 'Midi',
    evening: 'Soirée',
    midnight: 'Minuit',
  },
  customerGroups: {
    default: 'default',
    defaultInPreparation: 'default_in_preparation',
    subway: 'Subway',
    db: 'Deutsche Bahn',
    lunchroom: 'Lunchroom',
    weilands: 'Weilands',
    cinemaxx: 'Cinemaxx',
    fbf: 'Food By Friends',
    district: 'District',
    slush: 'Slush',
    foodlounge: 'Foodlounge',
    mado: 'Mado',
    depot: 'Depot',
    nordsee: 'Nordsee',
    jamies: 'Jamies',
    atlantikFisch: 'Atlantik Fisch',
    moschmosch: 'Moschmosch',
    kamps: 'Kamps',
    coffeeFellows: 'Coffee Fellows',
    hofbrauhaus: 'Hofbrauhaus',
    saray: 'Saray',
    starbucks: 'Starbucks',
    dimatest: 'Dima Test',
    maloa: 'Maloa',
    werk5: 'Werk 5',
    epi: 'EPI',
    iamlove: 'IAMLOVE',
    beetsAndRoots: 'Beets and Roots',
    beetsAndRootsSecond: 'Beets and Roots 2',
    losteria: "L'Osteria",
    shiso: 'Shiso',
    snackElize: 'Snack Elize',
    tabilo: 'TuR_RES',
    tabiloSim: 'TuR_Simphony',
    turAbnahmeRes: 'TUR Abnahme RES',
    turAbnahmeSim: 'TUR Abnahme Sim',
    effeGold: 'Effe & Gold',
    lacantine: 'La cantine',
    eam: 'Eam',
    naupaka: 'Naupaka',
    sportsbar: 'Sportsbar',
    cigkoftem: 'Cigkoftem',
    sushisupply: 'Sushi Supply',
    the_ash: 'The Ash',
    markthalle: 'Markthalle',
    sashimi: 'Sashimi',
    bonboncha: 'Bonboncha',
    hulala: 'Hulala',
    backwerk_schweiz: 'Backwerk Schweiz',
    backwerkCh: 'Backwerk CH',
    backfactory: 'Backfactory',
    teedeli: 'Teedeli',
    wohlfuhler: 'Wohlfuhler',
    asiaGourmet: 'Asia Gourmet',
    frittenwerkSecond: 'Frittenwerk 2',
    ricerepublic: 'RiceRepublic',
    goodbytz: 'Goodbytz',
    piratenBurger: 'Piraten Burger',
    tournesol: 'Tournesol',
    brezelKoenig: 'Brezelkönig',
    vincentVegan: 'Vincent Vegan',
    papais: 'Papais',
    theCup: 'The Cup',
    marseilleFoodMarket: 'Marseille Food Market',
    olearys: 'Olearys',
    iceland: 'Iceland',
    tableDot: 'Table Dot',
    sevenByBat: 'Seven by BAT',
    breakBurger: 'Break Burger',
    wok: 'Wok',
    oakberry: 'Oakberry',
    trz: 'TRZ',
    monkeyBurger: 'Monkey Burger',
    pastaFee: 'Pasta Fee',
    potatoe: 'Potatoe',
    pibose: 'Pibosa',
    shiangMing: 'Shiang Ming',
  },
  error: {
    notFound: "Malheureusement, la page n'a pas pu être trouvée.",
    forbidden: 'Droits manquants.',
    goHome: 'Retour au début',
    forbiddenText: 'Fehlende Rechte.',
    notFoundText: "La page n'a pas pu être trouvée.",
  },
  foodcard: {
    index: 'Foodcard verwalten',
    export: 'Foodcard exportieren',
    import: 'Foodcard importieren',
    importCvs: 'Importer une carte alimentaire (*.csv)',
    importNutritionCsv: 'Importer des éléments nutritionnels (*.csv)',
  },
  home: {
    index: 'Home',
    welcome: 'Bienvenue !',
  },
  tag: {
    index: 'Manage Tags',
    overview: 'Overview',
    add: 'Add Tag',
    empty:
      // tslint:disable-next-line:max-line-length
      "It looks like you haven't added any tags yet. Create your first tag to assign them to your articles and options.",
    form: {
      name: 'Name',
      identifier: 'Identifer',
    },
    table: {
      name: 'Name',
      identifier: 'Identifier',
    },
  },
  information: {
    index: 'Manage Information',
    overview: 'Overview',
    add: 'Add Information',
    edit: 'Edit Information',
    addAction: 'Add Action',
    removeAction: 'Remove Action',
    actions: 'Actions',
    empty:
      "It looks like you haven't added any information yet. Create your first information to inform customers about upcoming events and promos.",
    actionTypes: {
      primary: 'Primary',
      secondary: 'Secondary',
      cancel: 'Cancel',
    },
    form: {
      image: 'Image',
      title: 'Title',
      from: 'From',
      to: 'To',
      description: 'Description',
      url: 'Url',
      action: 'Action',
      actionType: 'Action Type',
      actionValue: 'Action Value',
      actionHtmlContent: 'HTML Content',
      actionName: 'Action Name',
    },
    table: {
      title: 'Title',
      description: 'Description',
      from: 'From',
      to: 'To',
      url: 'Url',
    },
  },
  role: {
    index: 'Modifier les utilisateurs',
    overview: 'Übersicht',
    create: 'Role erstellen',
    edit: 'Role bearbeiten',
    empty: "It looks like you haven't created any roles yet. Create your first role to give users granular access.",
    help: 'System Roles können nicht mehr geändert werden.',
    add: 'Role hinzufügen',
    table: {
      name: 'Name',
      slug: 'Slug',
      isSystem: 'System Role',
      permissions: 'Permissions',
    },
    form: {
      name: 'Name',
      slug: 'Slug (unique identifier)',
      isSystem: 'System role (if active, role cannot be changed.)',
      permissions: 'Permissions',
    },
  },
  promo: {
    index: 'Codes promotionnels',
    create: 'Ajouter Code Promotionnnell',
    edit: 'Modifier Code Promotionnnell',
    exportCsv: 'Export',
    exportSummary: 'Exporter Résumé',
    exportCount: 'Export Activation Summary',
    overviewCsv: 'ExporterCode Promotionnnell',
    overview: "Vue d'ensemble",
    add: 'Ajouter Code Promotionnnel',
    addMultiple: 'Ajouter Plusierus Codes Individuelles',
    disableCodes: 'Désactiver Code Promotionnnell',
    disableHint: "Veuillez d'abord choisir un restaurant",
    notification: {
      disableSuccess: 'Codes de promotion désactivés avec succès.',
    },
    types: {
      absolute: 'Remise en valeur',
      relative: 'Remise en pourcentage',
      bogo: 'BOGO',
      deliveryFee: 'No Delivery Free',
      giftCard: 'Gift Card',
      reward: 'Points de fidélité',
      freeArticle: 'Produit gratuit',
      void: 'Void',
      absoluteArticle: 'Produit (Remise en valeur)',
      relativeArticle: 'Produit (Remise en pourcentage)',
      mostExpensiveArticleDiscountRelative: "Remise sur l'article le plus cher (relatif)",
      teedeliPremium: 'Remise sur article (prime)',
    },
    table: {
      name: 'Nom',
      code: 'Code',
      customerGroup: 'Customer Group',
      mov: 'Valeur minimale de la commande',
      from: 'Valable à partir de',
      to: "Valable jusqu'au",
      disabled: 'Désactiver (Venue only)',
      venues: 'Restaurants',
      type: 'Type',
      value: 'Valeur',
      active: 'Actif (Global)',
      disabledBy: 'Disabled by',
      countries: 'Countries',
      states: 'States',
      amount: 'Utilisation maximale',
    },
    form: {
      offerPeriod: 'Durée de validité',
      name: 'Nom',
      code: 'Code',
      description: 'Description',
      article: 'Produit',
      venue: 'Venue',
      valid: 'Valid',
      generateAmount: 'Quantité',
      label: 'Venue Label',
      isMultipleApplicable: 'Peut être utilisé par un client plusieurs fois',
      uploadGiftCards: 'CSV file with Gift Cards',
      customerGroup: 'Customer Group',
      countries: 'Pays',
      states: 'States/Regions',
      codes: 'Promo Codes',
      venues: 'Restaurants',
      type: 'Type',
      freeArticle: 'Article Gratiute',
      bogoArticles: 'Articles (le produit le moins cher du panier sera utilisé pour le bogo)',
      mov: 'Valeur minimale de la commande',
      amount: 'Utilisation maximale',
      active: 'Active',
      value: 'Valeur',
      discount: 'Remise',
      percent: 'Valeur (par exemple 20%, veuillez saisir 0.2)',
    },
    standardAvailability: 'Standard Availability',
    terminalAvailability: 'Terminal Availability',
    preorderAvailability: 'Preorder Availability',
    webAvailability: 'Web Availability',
    weekdaysAvailability: 'Weekdays Availability',
    cateringAvailability: 'Catering Availability',
  },
  coupon: {
    index: 'Codes promotionnels',
    create: 'Ajouter Code Promotionnnell',
    edit: 'Modifier Code Promotionnnell',
    exportCsv: 'Export',
    exportSummary: 'Exporter Résumé',
    exportCount: 'Export Activation Summary',
    overviewCsv: 'ExporterCode Promotionnnell',
    overview: "Vue d'ensemble",
    add: 'Ajouter Code Promotionnnel',
    addMultiple: 'Ajouter Plusierus Codes Individuelles',
    disableCodes: 'Désactiver Code Promotionnnell',
    disableHint: "Veuillez d'abord choisir un restaurant",
    notification: {
      disableSuccess: 'Codes de promotion désactivés avec succès.',
    },
    form: {
      offerPeriod: 'Durée de validité',
      name: 'Nom',
      code: 'Code',
      article: 'Produit',
      venue: 'Venue',
      valid: 'Valid',
      generateAmount: 'Quantité',
      label: 'Venue Label',
      isMultipleApplicable: 'Peut être utilisé par un client plusieurs fois',
      uploadGiftCards: 'CSV file with Gift Cards',
      customerGroup: 'Customer Group',
      countries: 'Pays',
      states: 'States/Regions',
      codes: 'Coupon',
      venues: 'Restaurants',
      type: 'Type',
      freeArticle: 'Article Gratiute',
      bogoArticles: 'Articles (le produit le moins cher du panier sera utilisé pour le bogo)',
      mov: 'Valeur minimale de la commande',
      amount: 'Utilisation maximale',
      active: 'Active',
      value: 'Valeur',
      discount: 'Remise',
      percent: 'Valeur (par exemple 20%, veuillez saisir 0.2)',
    },
    types: {
      absolute: 'Remise en valeur',
      relative: 'Remise en pourcentage',
      bogo: 'BOGO',
      deliveryFee: 'No Delivery Free',
      giftCard: 'Gift Card',
      reward: 'Points de fidélité',
      freeArticle: 'Produit gratuit',
      void: 'Void',
      absoluteArticle: 'Produit (Remise en valeur)',
      relativeArticle: 'Produit (Remise en pourcentage)',
      plusOneFree: '1 + 1 Free',
    },
    table: {
      name: 'Nom',
      code: 'Code',
      customerGroup: 'Customer Group',
      mov: 'Valeur minimale de la commande',
      from: 'Valable à partir de',
      to: "Valable jusqu'au",
      disabled: 'Désactiver (Venue only)',
      venues: 'Restaurants',
      type: 'Type',
      value: 'Valeur',
      active: 'Actif (Global)',
      disabledBy: 'Disabled by',
      countries: 'Countries',
      states: 'States',
      amount: 'Utilisation maximale',
    },
    tabs: {
      general: 'Allgemein',
      availability: 'Verfügbarkeit',
    },
    standardAvailability: 'Standard Availability',
    terminalAvailability: 'Terminal Availability',
    preorderAvailability: 'Preorder Availability',
    webAvailability: 'Web Availability',
    weekdaysAvailability: 'Weekdays Availability',
    cateringAvailability: 'Catering Availability',
  },
  billing: {
    index: 'Rapports',
    billing: 'Rapport des commandes (PDF En détail)',
    orderCsv: 'Liste des commandes (CSV)',
    foodspotCsv: 'Export Foodspot (CSV)',
    paymentMethodCsv: 'Liste des commandes par mode de paiement (CSV)',
    summary: 'Rapport des commandes et de la commission (PDF)',
    form: {
      venue: 'Restaurant',
      flavor: 'Flavor',
      customerGroup: 'CustomerGroup',
      withoutTags: 'Ne pas filtrer les tags <empty>',
      showExpanded: 'Show expanded data',
      subscribedToMailing: 'Subscribed to newsletter',
      filterEmptyByMail: 'Filter empty by mail',
      typeExportFile: 'Type de fichier exporté',
    },
  },
  userExport: {
    index: "Exportation d'utilisateurs",
    userExport: "Exportation d'utilisateurs",
    userCsv: 'Exporter les utilisateurs',
    userWithNewsletterCsv: 'Exporter les utilisateurs avec la newsletter',
    form: {},
  },
  user: {
    index: 'Gestion des utilisateurs',
    overview: "Vue d'ensemble",
    changePassword: 'Modifier le mot de passe',
    create: 'Créer utilisateur',
    help: 'Veuillez faire attention en modifiant des utilisateurs',
    edit: 'Modifier un utilisateur',
    add: 'Ajouter un utilisateur',
    table: {
      name: 'Nom',
      email: 'Courrier électronique',
      code: 'Code',
      venues: 'Restaurants',
      venueLabels: 'Restaurant Labels',
      role: 'Role',
    },
    form: {
      name: 'Nom',
      email: 'Courrier électronique',
      password: 'Mot de passe',
      passwordConfirmation: 'Confirmer le mot de passe',
      venues: 'Restaurant',
      role: 'Role',
    },
  },
  printer: {
    index: "Gérer l'imprimante",
    overview: "Vue d'ensemble",
    edit: 'Modifier Imprimeur',
    customerGroup: 'Customer Group',
    empty: "Il n'y a actuellement aucune imprimante disponible.",
    table: {
      name: 'Nom',
      articleCount: "Nombre d'articles",
      venue: 'Restaurant',
      startedAt: 'Allumé à',
      activated: 'Activé',
      lastSignal: 'Dernier signal',
      latestActivity: 'Dernière activité',
      restart: 'Relancer',
      restartInProgress: 'Redémarrage...',
      order: 'Commande',
    },
    form: {
      articles: 'Articles',
      name: 'Nom',
    },
  },
  customer: {
    index: 'Customers verwalten',
    overview: 'Übersicht',
    edit: 'Customer bearbeiten',
    create: 'Customer erstellen',
    add: 'Customer hinzufügen',
    import: 'Customer importieren',
    empty: 'Es liegen nioch keine Kunden vor',
    table: {
      userUid: 'User UID',
      name: 'Name',
      email: 'E-Mail',
      qr: 'QR-Code',
    },
    form: {
      email: 'E-Mail',
      gender: 'Geschlecht',
      name: 'Name',
      city: 'Stadt',
      street: 'Straße',
      number: 'Nummer',
      postalCode: 'PLZ',
    },
  },
  workflow: {
    index: 'Manage Workflows',
    overview: 'Overview',
    add: 'Add Workflow',
    import: 'Import Workflow',
    empty:
      // tslint:disable-next-line:max-line-length
      "It looks like you havent' added any workflows yet. Create your first workflow to allow your customers to order.",
    table: {
      status: 'Status',
      code: 'Order Code',
      type: 'Order Type',
      venues: 'Venues',
    },
    form: {
      file: 'File',
    },
  },
  constrain: {
    index: 'Manage Tradegroups',
    overview: 'Overview',
    add: 'Add Tradegroup',
    empty:
      // tslint:disable-next-line:max-line-length
      "It looks like you haven't added any tradegroups yet. Create your first tradegroup to assign them to your articles.",
    form: {
      name: 'Name',
      identifier: 'Identifer',
      active: 'Active',
    },
    table: {
      name: 'Name',
      identifier: 'Identifier',
      active: 'Active',
    },
  },
  printGroups: {
    index: 'Manage Printgroups',
    overview: 'Overview',
    add: 'Add Printgroups',
    empty:
      // tslint:disable-next-line:max-line-length
      "It looks like you haven't added any Printgroups yet. Create your first Printgroups to assign them to your articles.",
    form: {
      name: 'Name',
      identifier: 'Identifer',
      active: 'Active',
    },
    table: {
      name: 'Name',
      identifier: 'Identifier',
      active: 'Active',
    },
  },
  languages: {
    de: 'Allemand',
    en: 'Anglais',
    fr: 'Français',
    nl: 'Niederländisch',
    it: 'Italienisch',
    pl: 'Polnisch',
    es: 'Spanisch',
  },
  legalForms: {
    ag: 'AG',
    gmbh: 'GmbH',
    ltd: 'UG',
    kg: 'KG',
    pe: 'Einzelgesellschaft',
    other: 'Andere',
    sa: 'SA',
    sarl: 'SARL',
    eurl: 'EURL',
    sas: 'SAS',
    autres: 'AUTRES',
  },
  periods: {
    daily: 'Quotidiennement',
    weekly: 'Hebdomadaire',
    monthly: 'Mensuel',
    never: 'Pas de rapport',
  },
  locTypes: {
    bar: 'Bar',
    club: 'Club',
    restaurant: 'Restaurant',
    db: 'Deutsche Bahn',
    cinema: 'Kino',
    other: 'Other',
  },
  paymentTypes: {
    cc: 'Carte de crédit',
    pp: 'PayPal',
    sepa: 'SEPA',
    sb: 'Klarna',
  },
  nav: {
    dashboard: 'Dashboard',
    foodcard: 'Menu',
    customer: 'Customers',
    venue: 'Restaurants',
    payment: 'Paiements',
    user: 'Utilisateurs',
    table: 'Tableaux',
    tag: 'Tags',
    billing: 'Rapports',
    userExport: 'User export',
    sales: 'Ventes',
    workflow: 'Workflows',
    printer: 'Imprimante',
    tradegroup: 'Tradegroups',
    printGroups: 'Printgroups',
    provision: 'Facturation',
    statistic: 'Statistiques',
    analytic: 'Analytics',
    receipts: 'Receipts',
    selectVenue: 'Sélectionnez un restaurant',
    category: 'Category',
    article: 'Articles',
    option: 'Options',
    reporting: 'Rapports',
    import: 'Import',
    role: 'Role',
    promo: 'Codes promotionnels',
    coupon: 'Coupon',
    uiComponents: 'UI Components',
    uiSlides: 'Slides',
    uiSlider: 'Slider',
    uiCard: 'Card',
    uiBanner: 'Banner',
  },
  auth: {
    logout: 'Déconnexion',
    login: 'Connexion',
    forgotPassword: 'Mot de passe oublié',
    resetPassword: 'réinitialiser le mot de passe',
    reset: 'réinitialiser le mot de passe',
    form: {
      password: 'Mot de passe',
      passwordConfirmation: 'Répéter le mot de passe',
      passwordHint:
        'Le mot de passe doit comporter au moins 8 caractères, 1 majuscule et 1 chiffre ou caractère spécial.',
      email: 'Courrier electronique',
    },
    notification: {
      forgotPassword:
        "Nous vous enverrons un lien vers le nouveau mot de passe à l'adresse électronique que vous aurez indiquée.",
      resetPassword: 'Votre mot de passe a été réinitialisé avec succès.',
      forbidden: "Vous n'avez pas de droits d'accès.",
      login: 'Connexion réussie!',
      logout: 'Déconnexion réussie.',
      resetPasswordError: "Réinitialisation non réussie. Veuillez réessayer ou contacter le service d'assistance.",
    },
  },
  register: {
    index: 'Inscription',
    account: 'Compte',
    register: 'Inscription',
    documentInfo:
      'Veuillez remplir les données suivantes pour générer vos mentions légales, les conditions générales et la politique de confidentialité pour votre site. Ces documents sont nécessaires.',
    success: 'Inscription réussi',
    addVenue: 'Ajouter un restaurant',
    venue: 'Créer un restaurant',
    successText: 'Votre inscription a réussi. Vous recevrez un e-mail dès que votre restaurant aura été mis en place.',
    location: 'Restaurant Details',
    invoiceReceiver: 'Propriétaire de restaurant',
    dialog: {
      venue: 'Voulez-vous mettre en place votre restaurant maintenant ? Vous pouvez aussi le faire après la connexion.',
    },
    form: {
      venue: 'Restaurant',
      name: 'Nom',
      firstName: 'Prénom',
      privacy: 'Par la présente j’accepte les dispositions sur la protection des données de la Sterlix GmbH.',
      privacyLink: 'Accéder aux dispositions sur la protection des données',
      lastName: 'Nom de famille',
      phone: 'Numéro de téléphone',
      email: 'E-mail',
      password: 'Mot de passe',
      passwordConfirmation: 'Répéter le mot de passe',
      passwordHint:
        'Le mot de passe doit comporter au moins 8 caractères, 1 majuscule et 1 chiffre ou caractère spécial.',
    },
  },
  article: {
    index: 'Gérer les articles',
    create: 'Ajouter un nouvel article',
    edit: "Modifier l'article",
    add: 'Ajouter un article',
    overview: "Vue d'ensemble",
    selectCategory: 'Veuillez sélectionner une catégorie',
    general: 'Changer des prix. ATTENTION! Metez un point à place de la virgule (p.e 4.50).',
    meta: 'Meta',
    images: 'Images',
    options: 'Options',
    mappings: 'Article Mappings',
    defaults: 'Article Defaults',
    depArticles: 'Articles (max: {max})',
    availableHours: 'Heures disponibles',
    addGroupDependency: 'Add Dependency',
    addGroupReverseDependency: 'Add Reverse Dependencies',
    addMappingArticle: 'Add Mapping Article',
    addDepArticles: 'Add Dependency Articles',
    addReverseDepArticles: 'Add Reverse Dependency Articles',
    dependencies: 'Dependencies',
    reverseDependencies: 'Reverse dependencies',
    addDefault: 'Add Default',
    price: 'Prix',
    priceLowToHigh: 'De bas en haut',
    priceHighToLow: 'Haut en bas',
    tabs: {
      general: 'General',
      images: 'Images',
      meta: 'Meta',
      options: 'Options',
      availableHours: 'Available Hours',
      allergens_and_additives: 'Allergènes et Additifs',
      nutritions: 'Nutrition',
    },
    table: {
      name: 'Nom',
      stock: 'Stock',
      price: 'Prix',
      active: 'Activ',
      assets: 'Image',
      icon: 'Icon',
      banner: 'Banner',
      description: 'Description',
      number: 'Numéro',
      gtin: 'GTIN',
      visible: 'Disponible',
      rowsPerPage: 'Rows Per Page',
    },
    form: {
      name: 'Nom',
      description: 'Description',
      price: 'Prix',
      replacesMainArticle: 'Replaces Main Article',
      gtin: 'GTIN (Global Trade Identifier Number)',
      stock: 'Stock',
      image: 'Image',
      banner: 'Banner',
      icon: 'Icon',
      active: 'Activ',
      tags: 'Tags',
      group: 'Optiongroup',
      shortDescription: 'Kurzbeschreibung',
      articles: 'Articles',
      article: 'Article',
      visible: 'Visible',
      printerSort: 'Printer Sort',
      quantity: 'Quantity',
      constrains: 'Tradegroups',
      printGroups: 'Printgroups',
      rateable: 'Rateable',
      taxInside: 'Tax Inside',
      taxTakeaway: 'Tax Takeaway',
      isControlArticle: 'Control Article',
      takeaway: 'Takeaway',
      inside: 'Inside',
      displayPrice: 'Display Price',
      isPrintable: 'Printable',
      isRateable: 'Rateable',
      info: 'Info',
      sort: 'Sort',
      mappedArticle: 'Mapped Article',
      category: 'Category',
      number: 'Number',
      deposit: 'Deposit',
      fsk: 'FSK',
      main: 'Main Article',
      kcal: 'Kcal',
      options: 'Options',
      allergenes: {
        allergenes: 'Allergènes',
        cerealsWithGluten: 'Céréales contenant du gluten',
        crustaceans: 'Crustacés et produits dérivés',
        eggs: 'Oeufs et produits dérivés',
        fish: 'Poissons et produits dérivés',
        peanuts: 'Arachides et produits dérivés',
        soya: 'Soja (graines) et produits dérivés',
        milk: 'Lait et produits dérivés',
        nuts: 'Noix',
        celery: 'Céleri et produits dérivés',
        mustard: 'Moutarde et produits dérivés',
        sesame: 'Graines de sésame et produits dérivés',
        sulfur: 'Anhydride sulfureux et sulfites',
        lupins: 'Lupins et produits dérivés',
        molluscs: 'Mollusques et produits dérivés',
        corn: 'Maïs et produits dérivés',
        wheat: 'Blé et produits dérivés',
        rye: 'Seigle et produits dérivés',
        barley: 'Orge et produits dérivés',
        oats: 'Avoine et produits dérivés',
        spelt: 'Epeautre et produits dérivés',
        khorasan: 'Khorasan et produits dérivés',
        walnuts: 'Noix et produits dérivés',
        pecanNuts: 'Noix de pécan et produits dérivés',
        brazilNuts: 'Noix du Brésil et produits dérivés',
        pistachioNuts: 'Pistaches et produits dérivés',
        macadamiaNuts: 'Noix de macadamia et produits dérivés',
        almonds: 'Amandes et produits dérivés',
        hazelnuts: 'Noisettes et produits dérivés',
        cashews: 'Noix de cajou et produits dérivés',
        seeds: 'Semences et produits dérivés',
        poppySeeds: 'Graines de pavot et produits dérivés',
        sunflowerSeeds: 'Graines de tournesol et produits dérivés',
        poultryMeat: 'Viande de volaille et produits dérivés',
        beef: 'Viande bovine et produits dérivés',
        pigmeat: 'Viande porcine et produits dérivés',
      },
      additives: {
        additives: 'Additifs',
        1: 'avec colorant',
        2: 'avec conservateur',
        3: 'avec antioxydant',
        4: 'avec exhausteur de goût',
        5: 'sulfuré',
        6: 'noirci',
        7: 'avec phosphate',
        8: 'avec protéines de lait (pour produits à base de viande)',
        9: 'caféiné',
        10: 'quinine',
        11: 'avec édulcorant',
        12: 'ciré',
        13: 'Nouveau',
      },
      nutrition: {
        portionInG: 'Portion (g)',
        energyKcalPerPortion: 'Énergie (kcal)',
        energyKcalPer100g: 'Énergie (kcal/100g)',
        energyKjPerPortion: 'Énergie (kj)',
        energyKjPer100g: 'Énergie (kj/100g)',
        fatPerPortion: 'Gras (g)',
        fatPer100g: 'Matière grasse (g/100g)',
        saturatesPerPortion: 'Saturés (g)',
        saturatesPer100g: 'Saturés (g/100g)',
        carbohydratePerPortion: 'Glucides (g)',
        carbohydratePer100g: 'Glucides (g/100g)',
        sugarPerPortion: 'Sucre (g)',
        sugarPer100g: 'Sucre (g/100g)',
        proteinPerPortion: 'Protéine (g)',
        proteinPer100g: 'Protéines (g/100g)',
        saltPerPortion: 'Sel (g)',
        saltPer100g: 'Sel (g/100g)',
      },
      features: {
        features: 'Fonctionnalités',
        vegan: 'Végétalien',
        vegetarian: 'Végétarien',
        glutenFree: 'Sans gluten',
        noCarb: 'Pas de glucides',
        alkaline: 'Alcaline',
        lactoseFree: 'Sans lactose',
      },
    },
  },
  translation: {
    start: "La traduction a commencé. Cela peut prendre jusqu'à 10 minutes",
  },
  payment: {
    index: 'Paiements',
    overview: "Vue d'ensemble",
    refundArticle: "Rembourser l'article",
    refundOrder: 'Rembourser la commande',
    refundQuestion: 'Êtes-vous sûr de initier un rembourssement. Cette action ne peut être révoquée.',
    refunded: 'Is part of a refund',
    order: 'Commande',
    refund: 'Remboursé',
    searchCode: 'Recherche code de commande...',
    methods: {
      credit_card: 'Carte de crédit',
      sepa: 'SEPA',
      sofortbanking: 'Klarna',
      paypal: 'PayPal',
    },
    table: {
      sum: 'Total',
      status: 'Status',
      hasRefunds: 'Remboursements',
      method: 'Mode de paiement',
      createdAt: 'Date',
    },
  },
  option: {
    index: 'Options bearbeiten',
    create: 'Option hinzufügen',
    edit: 'Option bearbeiten',
    add: 'Option hinzufügen',
    unlinkedOptions: 'options non liées',
    empty:
      "It looks like you haven't added any options yet. Create your first option group to make even better and customizable articles.",
    overview: 'Übersicht',
    tabs: {
      general: 'General',
      articles: 'Articles',
    },
    articles: {
      table: {
        name: 'Name',
        description: 'Beschreibung',
        price: 'Preis',
        assets: 'Images',
        active: 'Activ',
        visible: 'Visible',
        number: 'Nombre',
      },
      addTitle: 'Add article',
      editTitle: 'Edit article',
    },
    table: {
      name: 'Name',
      description: 'Beschreibung',
      requiredAmount: 'Required Amount',
      limit: 'Limit',
      hasMultiple: 'Has Multiple',
      sortOrder: 'Sort',
      article: 'Article',
    },
    form: {
      name: 'Name',
      description: 'Beschreibung',
      shortDescription: 'Kurzbeschreibung',
      image: 'Image',
      limit: 'Limit',
      active: 'Activ',
      visible: 'Visible',
      sort: 'Sort',
      virtual: 'Virtual',
      requiredAmount: 'Required Amount',
      hasMultiple: 'Multiple',
      displayIdentifiers: 'Display Identifiers',
      displayMode: 'Display Mode',
      displayModeSot: 'Display Mode SOT',
      displayModeMobile: 'Display Mode Mobile',
      tags: 'Tags',
      printGroups: 'Print Groups',
    },
    deleteHeader: 'Options de suppression',
    deleteInfo: 'Etes-vous sûr de vouloir supprimer ces options ? Cette action ne peut pas être annulée.',
    deleteSelected: 'Supprimer la sélection',
  },
  category: {
    index: 'Categories verwalten',
    create: 'Category anlegen',
    edit: 'Category bearbeiten',
    add: 'Category hinzufügen',
    overview: 'Übersicht',
    availableHours: 'Available Hours',
    table: {
      name: 'Name',
      sort: 'Sort',
      mainCategory: 'Main Category',
      visible: 'Visible',
    },
    form: {
      name: 'Name',
      mainCategory: 'Main Category',
      sort: 'Sort',
      displayMode: 'Display Mode',
      mainRecommendations: 'Main Recommendations',
      displayIdentifiers: 'Display Identifiers',
      visible: 'Visible',
      image: 'Image',
      icon: 'Icon',
    },
  },
  table: {
    index: 'Manage Tables',
    create: 'Create a new Table',
    exportQrs: 'Export QR-Codes',
    edit: 'Edit Table',
    add: 'Add Table',
    overview: 'Overview',
    table: {
      number: 'Number',
      description: 'Description',
      tags: 'Tags',
      qr: 'QR-Code',
    },
    form: {
      number: 'Number',
      description: 'Description',
    },
  },
  venue: {
    index: 'Gérer des restaurants',
    create: 'Ajouter un restaurant',
    panic: 'Suspendre',
    ppConnected: 'Statut du compte PayPal',
    ppConnectedSuccess: 'PayPal connecté',
    ppConnectedError: "PayPal n'est pas connecté ou a échoué.",
    createSimple: 'Créer un restaurant',
    overview: "Vue d'ensemble",
    add: 'Ajouter un restaurant',
    deliveryFees: 'Frais de livraison',
    edit: 'Modifier le restaurant',
    confirmation: {
      verify: {
        title: 'Verifier le restaurant',
        text: 'Par la même, vous confirmez que tous les prix, tous les articles et tous les réglages du restaurant sont exacts. En outre, vous avez téléchargé les documents CGV, mentions légales et protection des données.',
        action: 'Vérifier le restaurant',
      },
      publish: {
        title: 'Confirmation de publication',
        text: 'Par la même, vous confirmez que tous les prix, tous les articles et tous les réglages du restaurant sont exacts. En outre, vous avez téléchargé les documents CGV, mentions légales et protection des données.',
        action: 'Publier le restaurant',
      },
    },
    tooltipCannotSync: 'La synchronisation est déjà en cours. Veuillez patienter ou contacter un administrateur',
    verify: 'Vérifier',
    publish: 'Publier',
    credentials: 'Credentials',
    payment: 'Paiement',
    order: 'Ordre',
    preorder: 'Preorder',
    slotting: 'Paramètres de slots',
    ci: 'Checkout Integration',
    general: 'Informations Généralités',
    details: 'Détails',
    contact: 'Contact :',
    whiteLabel: 'whiteLabel',
    invoiceReceiver: 'Addresse de propritaire de restaurant',
    walleeCredentials: "Informations d'identification de Wallee",
    address: 'Adresse',
    settings: 'Paramètres',
    sizeSettings: 'Size Settings',
    preorderOffsets: 'Preorder Offsets',
    miscellaneous: 'Miscellaneous',
    preorderPayment: 'Preorder Payment',
    deliveryPayment: 'Preorder Delivery Payment',
    sepaCredentials: 'SEPA Credentials',
    ccCredentials: 'CreditCard Credentials',
    ppCredentials: 'PayPal Credentials',
    sbCredentials: 'SofortBanking Credentials',
    openingHours: "Heures d'ouverture",
    deliveryHours: 'Horaires de livraison',
    locTypes: {
      bar: 'Bar',
      club: 'Club',
      restaurant: 'Restaurant',
      db: 'Deutsche Bahn',
      cinema: 'Kino',
      other: 'Other',
    },
    tabs: {
      general: 'Informations Généralités',
      payment: 'Paiement',
      terminal: 'Terminal',
      preorder: 'Preorder',
      ci: 'CheckoutIntegration',
      order: 'Order',
      slotting: 'Slotting',
      openingHours: "Heures d'ouverture",
      deliveryHours: 'Horaires de livraison',
      deliveryProviders: 'Fournisseurs de livraison',
    },
    table: {
      name: 'Nom',
      shortId: 'Short-ID',
      email: 'Courrier électronique',
      street: 'Rue',
      number: 'Numéro de la maison',
      city: 'Localité',
      legalForm: 'Type de société',
      locType: 'Typ',
      isServiceActivated: 'Service activé',
    },
    form: {
      image: 'Image',
      name: 'Restaurant désignation',
      fullName: "Nom de l'entreprise",
      currency: 'Devise',
      country: 'Pays',
      locType: 'type de lieu',
      provisionRate: 'Taux de commission',
      panicEndAt: 'Suspendre (Désactive la réception des commandes)',
      provisionFixedRate: 'Fixer Provisionssatz',
      readableId: 'Short ID',
      deliveryRadius: 'Rayon de livraison',
      isPublished: 'Publié',
      streetAddress: 'Rue, Numero',
      registerCourt: 'Cour de registre',
      registerNumber: 'Numéro de registre',
      ppEmail: 'PayPal E-Mail',
      ppEnabled: 'Je propose le paiement par PayPal',
      orderPrinterTabletPackageInfo:
        'Je veux commander une Tablette avec imprimante de reçus intégrée pour {price}€ (net). Sans imprimante, vous ne recevez que les commandes par courrier électronique.',
      vrPaymentEnabled: 'Je propose le paiement par carte de credit, Apple Pay et Google Pay',
      rekaEnabled: 'Reka activé',
      twintEnabled: 'Twint activé',
      bcEnabled: 'Boncard activé',
      lchEnabled: 'Luncheck activé',
      pfEnabled: 'PistFinance E-Finance activé',
      pfcEnabled: 'PistFinance Card activé',
      walleeEnabled: 'Wallee activé',
      alipayEnabled: 'Alipay activé',
      wcEnabled: 'WeChat Pay activé',
      cwlEnabled: 'Worldline activé',
      chiefExecutives: 'Propriétaire',
      deliveryHours: 'Horaires de livraison',
      active: 'Active',
      virtualOrders: 'Allow Virtual Orders',
      stock: 'Stock',
      movDelivery: 'Valeur minimale de la commande',
      checkoutIntegration: 'Checkout Integration',
      printerIntegration: 'Printer Integration',
      readyOffset: 'Preparation Offset',
      interval: "Taille de l'intervalle",
      reserved: 'Commandes réservées',
      calculateDays: 'Count of days to calculate slots',
      max: 'Nombre maximum de commandes',
      notPickedOffset: 'Not picked offset',
      isServiceActivated: 'État du service (si non activé, la commande est désactivée)',
      isCallingSystemEnabled: "Système d'appel",
      legalForm: 'Société individuelle',
      email: 'Courrier électronique',
      orderEmail: 'E-Mail pour recevoir des commandes',
      storeEmail: 'E-Mail du restaurant',
      orderOffsetHint:
        "Veuillez indiquer combien de minutes après l'ouverture doivent s'écouler avant l'acceptation de la première commande, 60 = 1 heure",
      web: 'Website',
      terms: 'Modalités et conditions',
      imprint: 'Mentions légales',
      privacy: 'Data',
      standardOrders: 'Allow Standard Orders',
      preorderTakeAway: 'Activer le Click & Collect (récupération des commandes en restaurant)',
      preorderParkCollect: 'Activer le Park & Collect',
      preorderIn: 'Allow Preorder In House',
      preorderDelivery: 'Allow Delivery',
      autoAccept: 'Auto Accept in',
      autoAcceptTerminal: 'Auto Accept Terminal Order in',
      checkoutIn: 'Checkout In',
      printIn: 'Print In',
      autoReset: 'Auto Reset In',
      orderTimeout: "Délai d'expiration de la commande",
      orderTimeoutEnabled: "Activer le délai d'expiration de la commande",
      phone: 'Téléphone',
      city: 'Localité',
      area: 'Nearest City',
      street: 'Rue',
      number: 'N°',
      postalCode: 'Code postal',
      timezone: 'Zeitzone',
      customerGroup: 'Customer Group',
      sendProvisionReportPeriod: 'Envoi de rapports',
      lastInvoiceNumber: 'Last invoice number',
      sendProvisionReportPeriodNonInvoiceable: 'Envoyer le rapport de provision (sans numéro de facture)',
      vatNumber: 'Numéro de TVA',
      from: 'De',
      fee: 'Tarifs',
      secureCode: 'Secure Code',
      pdfUpload: 'Télécharger le fichier PDF',
      insertLink: 'Insérer un lien',
      nutritionTable: 'Tableau nutritionnel',
      latitude: 'Latitude',
      longitude: 'Longitude',
      paymentEnabled: 'Payment Enabled',
      sepaEnabled: 'SEPA Enabled',
      sbEnabled: 'SofortBanking Enabled',
      ccEnabled: 'CreditCard Enabled',
      posEnabled: 'POS Enabled',
      cashEnabled: 'Cash Enabled',
      secretKey: 'Secret Key',
      merchantName: 'Merchant Name',
      creditorId: 'Creditor Id',
      merchantId: 'Merchant Id',
      userId: 'User Id',
      spaceId: 'Space Id',
      userKey: 'User Key',
      articleSpanCount: 'Article Span Count',
      categorySpanCount: 'Category Span Count',
      fullWidthCategories: 'Categories On full Width',
      showInactiveArticles: 'Show Interactive Articles',
      askForTableNumber: 'Ask For Table Number',
      foodAndBeveragesSeparated: 'Food And Beverages Separated',
      toolbarTitleHidden: 'Toolbar Title Hidden',
      displayCirclePickerDescription: 'Display Circle Picker Description',
      toolbarCategoryListVisible: 'Toolbar Category List Visible',
      toolbarSidesVisible: 'Toolbar Sides Visible',
      dailyUnsnoozeTime: "Compensation d'article",
      multiLanguage: 'Multi language',
      printQr: 'Print QR',
      preorderOffset: 'Minimum Preorder Offset',
      firstPreorderOffset: 'Earliest Preorder Offset',
      lastPreorderOffset: 'Latest Preorder Offset',
      autoDeclineTime: 'Cancel time',
      uploadedFile: 'Fichier téléchargé',
      deliveryProviders: {
        orderLordEnabled: 'OrderLord Enabled',
        orderLordActive: 'OrderLord Available (set by administrator)',
        orderLordToken: 'OrderLord API Token',
        orderLordPassword: 'OrderLord API Password',
      },
      invoiceReceiver: {
        email: 'Alternative invoice receiver email address (otherwise contact email will be used)',
      },
      deliveryFeesAfterCreation:
        'Les frais de livraison peuvent être spécifiés dans les paramètres du lieu après la création.',
    },
    legal: {
      useTemplate: 'Utiliser le modèle standard',
      termsHtml: `<h1>Conditions générales de vente</h1><p></p>
      <h2>1. Conclusion du contrat</h2><ol><li><p>La présentation des produits dans notre site en ligne n'est pas une offre juridiquement contraignante, mais un catalogue non contraignant des produits que nous proposons. Si vous passez une commande en cliquant sur le bouton de commande, vous nous soumettez une offre pour conclure un contrat correspondant. L'acceptation a lieu lorsque le paiement de votre commande a été effectué avec succès ou lorsque nous avons expressément accepté votre offre. Un e-mail envoyé automatiquement par nos soins, confirmant la réception de votre commande, ne constitue pas une acceptation de votre offre.</p></li><li><p>Lors de la saisie de votre commande, vous avez la possibilité d'apporter des corrections aux données saisies. Veuillez utiliser la fonction "retour" de votre navigateur ou les options que nous avons prévues à cet effet.</p></li></ol><p></p>
      <h2>2. La langue du contrat, le stockage du texte du contrat et la correction des données saisies</h2><ol><li><p>La langue du contrat est le français.</p></li><li><p>Le texte du contrat n'est pas stocké par nos soins et ne peut pas être consulté par vous une fois le processus de commande terminé. Vous recevrez un courriel de confirmation de notre part lorsque vous enverrez votre commande, qui contient le contenu de votre commande.</p></li></ol><p></p>
      <h2>3. Réclamations pour défauts</h2><ol><li><p>Si le client est un entrepreneur au sens une personne morale de droit public ou un fonds spécial de droit public, nous décidons si nous devons remédier au défaut par une remise en état ou une nouvelle livraison.</p></li><li><p>À tous les autres égards et pour les consommateurs, les dispositions légales sont applicables.</p></li></ol><p></p>
      <h2>4. Responsabilité</h2><ol><li><p>La responsabilité en cas d'intention et de négligence grave est illimitée.</p></li><li><p>En cas de violation par simple négligence d'obligations contractuelles essentielles, la responsabilité est limitée aux dommages prévisibles et typiques du contrat. Les obligations contractuelles matérielles sont celles dont l'exécution est essentielle à la bonne exécution du contrat et sur le respect desquelles la partie lésée peut régulièrement compter.</p></li><li><p>Le paragraphe 2 ne s'applique pas aux droits résultant d'une atteinte à l'intégrité physique, à la santé ou à la vie, en cas d'action frauduleuse, en cas de prise en charge d'une garantie, en cas de responsabilité pour incapacité initiale d'exécution ou impossibilité d'exécution dont nous sommes responsables, ou pour les droits au titre de la loi sur la responsabilité du fait des produits.</p></li></ol><p></p>
      <h2>5. Non-existence d'un droit de rétractation</h2><p>Les produits que nous proposons sont des denrées alimentaires qui peuvent se s’alterer rapidement. Il n'y a donc pas de droit de rétractation.</p><p></p>
      <h2>6. Règlement extrajudiciaire des litiges</h2><ol><li><p>La Commission européenne a mis en place une plateforme de résolution extrajudiciaire des litiges sur le site <a href="http://ec.europa.eu/consumers/odr/." rel="noopener noreferrer nofollow">http://ec.europa.eu/consumers/odr/.</a></p></li><li><p>Nous ne sommes ni obligés ni disposés à participer à une procédure de règlement des litiges devant un conseil d'arbitrage en matière de consommation.</p></li></ol><p></p>
      <h2>7. Dispositions finales</h2><ol><li><p>Si une disposition des présentes conditions générales est ou devient nulle, invalide ou inapplicable en tout ou en partie, ou si une disposition nécessaire en soi n'est pas incluse, la validité et l'applicabilité de toutes les autres dispositions du présent accord ne seront pas affectées.</p></li><li><p>Si le client est un consommateur les dispositions suivantes s'appliquent : Le contrat est régi exclusivement par le droit de la République fédérale d'Allemagne, mais uniquement dans la mesure où le client n'est pas privé de la protection que lui assurent les dispositions impératives de l'État dans lequel il a sa résidence habituelle au moment de la passation de la commande. Si le client ne dispose pas d'un lieu de juridiction générale en Allemagne ou dans un autre État membre de l'UE, le lieu de juridiction exclusif pour tous les litiges découlant du présent contrat est notre siège social.</p></li>
      <li><p>Si le client est un entrepreneu le seul lieu de juridiction pour tous les litiges en rapport avec le présent contrat est notre siège social.</p></li></ol><p></p>`,
      imprintHtml: `<h1>Mentions legales</h1><p></p>
      <h2>SOCIÉTÉ</h2><p>{{company}}</p><p>{{street}}</p><p>{{postalCode}} {{city}}</p><p></p>
      <h2>Représenté par le ou les dirigeants:</h2><p>{{chiefExecutives}}</p><p></p>
      <h2>Contact</h2><p>Téléphone: {{phone}}</p><p>E-Mail: {{email}}</p><p>FAX: {{fax}}</p><p></p>
      <h2>Taxe sur la valeur ajoutée</h2><p>Numéro TVA : {{vatNumber}}</p><p></p>
      <h2>Règlement des litiges</h2><p>La Commission européenne met à disposition une plateforme de règlement des litiges en ligne (OS) : <a href="https://ec.europa.eu/consumers/odr." rel="noopener noreferrer nofollow">https://ec.europa.eu/consumers/odr.</a> Vous trouverez notre adresse électronique en haut de mention legales. Nous ne sommes ni disposés ni obligés de participer à une procédure de règlement des litiges devant un organisme de règlement des litiges de consommation.</p><p></p>
      <h2>Informations sur l'opérateur</h2><p>Sterlix GmbH n'est pas responsable du contenu de la page de commande ! Si vous avez des questions concernant votre commande, les produits/menus, les additifs ou les valeurs nutritionnelles, veuillez contacter directement le restaurant mentionné ci-dessus.</p>
      <p>Les mentions légales de l'opérateur se trouve sous le lien suivant : <a href="https://smoothr.de" rel="noopener noreferrer nofollow">https://smoothr.de</a></p><p></p>`,
      privacyHtml: `<h1>Déclaration de protection des données</h1><p></p>
      <h3><strong>Responsable</strong></h3>
      <p>{{company}}</p><p>{{street}}</p><p>{{postalCode}} {{city}}</p><p>Téléphone: {{phone}}</p><p>E-Mail: {{email}}</p><p>Fax: {{fax}}</p>
      <h3><strong>Questions sur la protection des données et l'exercice de vos droits</strong></h3>
      <p>Si vous avez des questions concernant la protection des données ou si vous souhaitez exercer les droits de protection des données auxquels vous avez droit, veuillez nous contacter en utilisant les coordonnées indiquées ci-dessus.</p>
      <h3><strong>Commander nos produits</strong></h3>
      <p>Avec votre commande, nous traitons les données fournies par vous dans la mesure nécessaire à l'exécution du contrat conclu avec vous. La base juridique du traitement est l'article 6, paragraphe 1, point b), RGPD.</p><p>En raison des exigences légales relatives aux commandes sur Internet, nous sommes obligés de vous envoyer une confirmation de commande électronique. La base juridique est l'article 6, paragraphe 1 c) de la RGPD. Dans la mesure où il existe des obligations légales d'enregistrement et de conservation en rapport avec la documentation de la conclusion et de l'exécution du contrat conclu avec vous (par exemple, conservation des preuves de la conclusion du contrat ou des factures), la base juridique du traitement correspondant est l'art. 6, al. 1 c) RGPD.</p><p>Nous traitons également vos données à des fins de détection et de prévention des fraudes sur la base de l'art. 6, al. 1, lettre f) de l'RGPD. Notre objectif est de nous protéger contre les transactions frauduleuses.</p><p>Les données qui sont stockées en relation avec la conclusion d'un contrat d'achat d'un produit seront effacées après l'expiration de l'obligation légale de stockage.</p>
      <h3><strong>Traitement du paiment</strong></h3>
      <p>Le paiement via Paypal est géré par PayPal (Europe) S.à r.l. et Cie, S.C.A. 22-24 Boulevard Royal L-2449 Luxembourg. PayPal est responsable du traitement des données.</p><p>Les paiements par carte de crédit, Google Pay et Apple Pay sont traités par VR Pay Saonestraße 3a, 60528 Francfort-sur-le-Main. VR Pay est responsable du traitement des données.</p>
      <h3><strong>Demandes de contact</strong></h3>
      <p>Si vous nous envoyez un message via l'une des options de contact proposées, nous utiliserons les données que vous nous fournissez pour traiter votre demande. La base juridique est notre intérêt légitime à répondre à votre demande conformément à l'article 6, paragraphe 1, point f), RGPD. Si votre demande sert à la conclusion d'un contrat avec nous, une autre base juridique pour le traitement est l'art. 6 al. 1 b) RGPD. Les données seront supprimées après le traitement de votre demande. Si nous sommes légalement tenus de conserver les données pendant une période plus longue, les données seront supprimées à l'expiration de la période correspondante.</p>
      <h3><strong>Vos droits</strong></h3>
      <p>En ce qui concerne vos données personnelles provenant du RGPD, vous bénéficiez notamment des droits suivants. Pour plus de détails, nous vous renvoyons aux dispositions légales.</p>
      <h3><strong>Droit à l'information</strong></h3>
      <p>Conformément à l'article 15 RGPD, vous avez le droit de nous demander de confirmer si des données à caractère personnel vous concernant sont traitées par nous. Si tel est le cas, vous avez le droit d'être informé de ces données à caractère personnel et de recevoir des informations complémentaires comme mentionné à l'article 15 RGPD.</p>
      <h3><strong>Droit de rectification</strong></h3>
      <p>Conformément à l'article 16 RGPD, vous avez le droit de nous demander de corriger sans délai les données personnelles incorrectes vous concernant. Compte tenu des finalités du traitement, vous avez également le droit de demander que les données à caractère personnel incomplètes soient complétées - également au moyen d'une déclaration complémentaire.</p>
      <h3><strong>Droit d'annulation</strong></h3>
      <p>Vous avez le droit de nous demander de supprimer immédiatement les données à caractère personnel vous concernant. Nous sommes tenus de supprimer immédiatement les données personnelles, à condition que les exigences pertinentes de l'art. 17 RGPD soient respectées. Pour plus de détails, veuillez vous référer à l'article 17 RGPD.</p>
      <h3><strong>Droit de limiter le traitement</strong></h3>
      <p>Conformément à l'article 18 RGPD, vous avez le droit, sous certaines conditions, de nous demander de limiter le traitement de vos données à caractère personnel.</p>
      <h3><strong>Droit à la transférabilité des données</strong></h3>
      <p>Aux termes de l'article 20 RGPD, vous avez le droit de recevoir les données à caractère personnel vous concernant que vous nous avez fournies dans un format structuré, commun et lisible par machine. En vertu de l'art. 20 RGPD, vous avez également le droit de transmettre sans entrave ces données à un autre responsable, pour autant que le traitement repose sur un consentement au sens de l'art. 6, al. 1, lettre a), RGPD ou de l'art. 9, al. 2, lettre a), RGPD ou sur un contrat au sens de l'art. 6, al. 1, lettre b), RGPD et que le traitement soit effectué par des procédés automatisés.</p>
      <h3><strong>Existence d'un droit de recours auprès de l'autorité de contrôle</strong></h3>
      <p>Conformément à l'article 77 RGPD et sans préjudice de tout autre recours administratif ou judiciaire, ils ont le droit de déposer une plainte auprès de l'autorité de contrôle. Ce droit existe notamment dans l'État membre dans lequel vous résidez, sur votre lieu de travail ou sur le lieu de l'infraction présumée, si vous estimez que le traitement des données à caractère personnel vous concernant est contraire à la RGPD.</p>
      <h3><strong>Droit d'opposition</strong></h3>
      <p><strong>Selon l'article 21 RGPD, vous avez le droit de vous opposer au traitement des données à caractère personnel vous concernant sur la base de l'article 6 paragraphe 1 lettres e ou f RGPD, y compris le profilage basé sur ces dispositions.</strong></p><p><strong>Lorsque nous traitons vos données à caractère personnel à des fins de marketing direct, vous avez le droit de vous opposer à tout moment au traitement des données à caractère personnel vous concernant à des fins de marketing, y compris le profilage, lorsqu'il est lié à ce marketing direct.</strong></p><p></p>`,
    },
  },
  analytic: {
    from: 'De',
    to: 'À',
  },
  sepa: {
    index: 'SEPA',
    title: 'Paramètres SEPA',
    mandate: 'Mandat',
    sepaMandate: 'SEPA Mandat',
    venues: 'Restaurants',
    createSepa: 'Créer un nouveau mandat SEPA',
    debit: 'SEPA Debit',
    payLast: 'Pay invoices of last 30 days',
    notification: {
      cancelError: 'Error while cancelling SEPA Mandate',
      cancelWarning:
        'Are you sure to cancel this SEPA Mandate? It will be removed and can not be used for payments anymore.',
      createError: 'Could not create new SEPA Mandate Registration Link',
      createWarning:
        'Etes vous sûr de vouloir créer un nouveau mandat SEPA ? Si un mandat SEPA existe déjà pour ce restaurant, celui ci sera annulé et ne pourra plus être utilisé.',
      payWarning: 'SEPA Mandate exists for this venue. Do you want to create a new SEPA Payment for this transaction?',
      payLastTransactionsWarning:
        'Are you sure to mandate payments for all invoices of the last 30 days for these venues? Afterwards single payments can be cancelled within 5 days. In this case please contact the support immediately.',
      payLastTransactionsSuccess:
        'invoices of the last 30 days paid. You will receive an email for each mandated payment. Please contact the support within 5 days, if you want to cancel one of the payments.',
    },
    form: {
      merchantId: 'Merchant Id',
      venues: 'Restaurants',
      merchantName: 'Merchant Name',
      sepaMandateRef: 'SEPA Mandate Reference',
      sepaMandateRegistrationLink: 'SEPA Mandate Registration Link',
      sepaMandateRegistrationError: 'SEPA Mandate Registration Error',
    },
  },
  stats: {
    index: 'Gestion des statistiques',
    indexAnalytics: 'Gestion des statistiques',
    totalNumberOfOrders: 'Nombre des commandes',
    avgOrdersPerDay: 'Ø Quantité Commande/jour',
    avgAmount: 'Ø Valeur des commandes',
    totalAmount: 'Montant total',
    totalSoldArticles: "Nombre d'articles vendus",
    highestDaysSale: "Chiffre d'affaires quotidien le plus élevé",
    totalPreCheckouts: 'Commandes generer',
    paymentMethod: 'Mode de paiements',
    avgOrderDuration: 'Ø Durée moyenne de la commande',
    totalTimeouts: "Nombre de temps d'arrêt",
    monthSales: 'Ventes mensuelles',
    selectVenue: 'Sélectionnez un restaurant',
    ui: {
      search: 'Chercher',
      bestSold: 'Meilleures ventes',
      worstSold: 'Produits moins vendus',
      ordersView: 'Vue des commandes',
      articleView: 'Article view',
      quantity: 'Quantité',
      orders: 'Commandes',
      price: 'Prix',
      refresh: 'Refresh',
      debug: 'TESTING',
      country: 'Pays',
      label: 'Sélectionner label',
      prod: 'LIVE',
      flavor: 'Select Flavor(s)',
      status: 'Sélectionner statut',
      table: 'Select Type(s)',
      type: 'Sélectionner type',
    },
    table: {
      order: 'Commande',
      date: 'Date',
      time: 'Heure',
      qty: 'Quantité',
      itemName: 'Articles',
      allCat: 'All Categories',
      cat: 'Categories',
      type: 'Type',
      status: 'Statut',
      table: 'Table',
      price: 'Prix/article',
      total: 'Total',
      name: 'Nom',
      orderDate: 'Date de commande',
      orderTime: 'Durée de commande',
      customer: 'Client',
      venue: 'Restaurant',
      subType: 'Sous-type',
    },
  },
  sales: {
    index: 'Vente de produits',
    ui: {
      category: 'Sélectionnez la (les) catégorie(s)',
      item: 'Sélectionnez le(s) article(s)',
    },
    table: {
      name: 'Nom',
      sales: 'Ventes',
      total: 'Total',
      cat: 'Categorie',
    },
  },
  footer: {
    imprint: 'Mentions légales',
    terms: 'Directive sur la protection des données',
  },
  validations: {
    unique_email: 'Le courrier électronique est déjà utilisé',
    readable_id: "L'identification est déjà prise.",
    venue_label: 'Le label de lieu existe déjà.',
    password:
      'Le mot de passe doit comporter au moins 8 caractères dont une majuscule ainsi qu’un chiffre ou un caractère spécial.',
    identifier: 'The identifier must contain only lowercase letters separated with a single underscore',
    date_before: '{field} doit etre avant {target}',
    time_before: '{field} doit etre avant  {target}',
    unique_dependency: '{field} double',
    smaller_than: '{field} doit etre moins que {target}.',
    greater_than: '{field} doit etre plus que {target}.',
    uppercase: 'Seulement des majuscules sont possibles',
    url_simple: 'Please enter a valid full URL with http/https',
    vatNumber: {
      info: 'Le numéro TVA, au moins 5 caractères sont nécessaires',
      error:
        "Le numero TVA n'est pas correct ou déjà utilisé par un autre utilisateur. Veuillez saisir un autre numéro de TVA ou contacter un administrateur pour trouver une solution au problème.",
    },
  },
};
