
import { Component, Prop } from 'vue-property-decorator';
import StackedForm from '../../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import VFormBuilder from '../../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import { Article } from '@/interfaces/models/Article';
import { Tag } from '@/interfaces/models/Tag';
import PriceByTypeForm from '@/components/article/form/PriceByTypeForm.vue';
import AvailabilityForm from '@/components/article/form/AvailabilityForm.vue';
import AllergensAndAdditivesForm from '@/components/article/form/AllergensAndAdditivesForm.vue';
import NutritionForm from '@/components/article/form/NutritionForm.vue';
import { PrintGroups } from '@/interfaces/models/PrintGroups';
import StyleForm from '@/components/article/form/StyleForm.vue';
import WoltArticleOptionsForm from '@/components/article/form/WoltArticleOptionsForm.vue';
import { Venue } from '@/interfaces/models/Venue';
import { replaceCommaWithDot } from '@/util/helper';
import AvailableHourForm from '@/components/article/form/AvailableHourForm.vue';
import { ArticleRelativeBrand } from '@/enums/ArticleRelativeBrand';
import JetArticleOptionsForm from '@/components/article/form/JetArticleOptionsForm.vue';
import UberEatsArticleOptionsForm from '@/components/article/form/UberEatsArticleOptionsForm.vue';
import { Constrain } from '@/interfaces/models/Constrain';

@Component({
  components: {
    UberEatsArticleOptionsForm,
    JetArticleOptionsForm,
    WoltArticleOptionsForm,
    AvailabilityForm,
    PriceByTypeForm,
    VFormBuilder,
    AllergensAndAdditivesForm,
    NutritionForm,
    StyleForm,
    AvailableHourForm,
  },
})
export default class OptionArticleForm extends mixins(StackedForm) {
  @Prop({ type: Array, default: () => [] }) public articles!: Article[];
  @Prop({ type: Array, required: true }) public tags!: Tag[];
  @Prop({ type: Array, required: false }) public printGroups!: PrintGroups[];
  @Prop({ type: Object, required: true }) public venue!: Venue;
  @Prop({ type: Array, required: false }) public constrains!: Constrain[];

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
      reset: () => void;
    };
    dimension: InstanceType<typeof VFormBuilder> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
      reset: () => void;
    };
    weightDetails: InstanceType<typeof VFormBuilder> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
    price: InstanceType<typeof PriceByTypeForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
      reset: () => void;
    };
    displayPrice: InstanceType<typeof PriceByTypeForm> & {
      getPrices: () => any;
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
      reset: () => void;
    };
    availableHours: InstanceType<typeof AvailableHourForm> & { getData: () => any };
    availability: InstanceType<typeof AvailabilityForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
      reset: () => void;
    };
    compound: InstanceType<typeof AllergensAndAdditivesForm> & {
      getData: () => any;
    };
    nutritions: InstanceType<typeof NutritionForm> & {
      getData: () => any;
    };
    requirements: InstanceType<typeof VFormBuilder> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
      reset: () => void;
    };
    measurement: InstanceType<typeof VFormBuilder> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
      reset: () => void;
    };
    manufacturer: InstanceType<typeof VFormBuilder> & {
      getData: () => any;
    };
    style: InstanceType<typeof StyleForm> & { getData: () => any };
    wolt: InstanceType<typeof WoltArticleOptionsForm> & { getData: () => any };
    jet: InstanceType<typeof JetArticleOptionsForm> & { getData: () => any };
    uberEats: InstanceType<typeof UberEatsArticleOptionsForm> & { getData: () => any };
  };

  public reset() {
    this.$refs.price.reset();
    this.$refs.displayPrice.reset();
    this.$refs.availability.reset();
    this.$refs.form.reset();
    this.$refs.measurement.reset();
  }

  get measurementItems() {
    return [
      { name: 'unit', label: 'article.form.unit', type: InputType.Select, items: this.units, default: null },
      {
        name: 'amount',
        label: 'article.form.amount',
        type: InputType.Text,
        default: null,
        rules: `numbers_with_comma_or_dot:${this.$t('article.form.amount')}`,
      },
    ];
  }

  get manufacturerItems() {
    return [
      { name: 'name', type: InputType.Text, label: 'article.form.name' },
      {
        name: 'address',
        type: InputType.Text,
        label: 'article.form.address',
      },
      {
        name: 'country',
        type: InputType.Text,
        label: 'article.form.country',
      },
    ];
  }

  get units() {
    return [
      { text: 'G', value: 'g' },
      { text: 'KG', value: 'kg' },
      { text: 'ML', value: 'ml' },
      { text: 'L', value: 'l' },
    ];
  }

  get items() {
    return [
      { name: 'assets', type: InputType.File, label: 'article.form.image', default: null, accept: 'image/*' },
      { name: 'icon', type: InputType.File, label: 'article.table.icon', default: null, accept: 'image/*' },
      { name: 'name', type: InputType.Language, useTextarea: true, label: 'article.form.name', rules: 'required' },
      { name: 'internalName', type: InputType.Textarea, label: 'article.form.internalName' },
      { name: 'optionNumber', type: InputType.Text, label: 'article.form.number', rules: 'required' },
      {
        name: 'price',
        type: InputType.Price,
        label: 'article.form.price',
        rules: 'required',
        disabled: this.isDisabledCallback,
      },
      { name: 'tags', type: InputType.Select, label: 'article.form.tags', items: this.tagItems, multiple: true },
      { name: 'externalId', type: InputType.Text, label: 'article.form.externalId' },
      { name: 'internalNote', type: InputType.Text, label: 'article.form.internalNote' },
      { name: 'gtin', type: InputType.Text, label: 'article.form.gtin' },
      { name: 'plu', type: InputType.Text, label: 'article.form.plu' },
      { name: 'description', type: InputType.Language, useTextarea: true, label: 'article.form.description' },
      {
        name: 'shortDescription',
        type: InputType.Language,
        useTextarea: true,
        label: 'article.form.shortDescription',
      },
      { name: 'info', type: InputType.Language, useRichText: true, label: 'article.form.info' },
      { name: 'visible', type: InputType.Checkbox, label: 'article.form.visible', default: true },
      { name: 'isActive', type: InputType.Checkbox, label: 'article.form.active', default: true },
      {
        name: 'replacesMainArticle',
        type: InputType.Select,
        label: 'article.form.replacesMainArticle',
        items: this.articleItems,
        default: null,
      },
      { name: 'priceIsLocked', type: InputType.Checkbox, label: 'article.form.priceIsLocked', default: false },
      { name: 'displayPrice', type: InputType.Price, label: 'article.form.displayPrice', default: null },
      {
        name: 'taxInside',
        type: InputType.Select,
        label: 'article.form.taxInside',
        rules: 'required',
        items: this.taxes,
        default: '-1',
      },
      {
        name: 'taxTakeaway',
        type: InputType.Select,
        label: 'article.form.taxTakeaway',
        rules: 'required',
        items: this.taxes,
        default: '-1',
      },
      {
        name: 'articleRelativeBrand',
        type: InputType.Select,
        items: this.brands,
        label: 'article.form.articleRelativeBrand',
      },
      {
        name: 'loyaltyId',
        label: 'article.form.loyaltyId',
        type: InputType.Text,
      },
      {
        name: 'requiredLoyaltyPoints',
        label: 'article.form.requiredLoyaltyPoints',
        type: InputType.Text,
        rules: 'integer',
      },
      { name: 'takeaway', type: InputType.Checkbox, label: 'article.form.takeaway', default: true },
      { name: 'isPrintable', type: InputType.Checkbox, label: 'article.form.isPrintable', default: true },
      { name: 'isRateable', type: InputType.Checkbox, label: 'article.form.isRateable', default: true },
      { name: 'sortOrder', type: InputType.Text, label: 'article.form.sort', rules: 'integer', default: 1 },
      {
        name: 'printerSortOrder',
        type: InputType.Text,
        label: 'article.form.printerSort',
        rules: 'integer',
        default: 1,
      },
      {
        name: 'deposit',
        type: InputType.Select,
        label: 'article.form.deposit',
        items: this.depositTypes,
        default: '0.00',
      },
      { name: 'kcal', type: InputType.Text, label: 'article.form.kcal', rules: 'integer', default: 0 },
      { name: 'fsk', type: InputType.Select, label: 'article.form.fsk', items: this.fskItems, default: 0 },
      { name: 'alcoholPercentage', type: InputType.Decimal, label: 'article.form.alcoholPercentage' },
      { name: 'caffeineMg', type: InputType.Decimal, label: 'article.form.caffeineMg', rules: 'integer' },
      {
        name: 'constrains',
        type: InputType.Select,
        label: 'article.form.constrains',
        items: this.constrainItems,
        multiple: true,
      },
      {
        name: 'printGroups',
        type: InputType.Select,
        label: 'article.form.printGroups',
        items: this.printGroupsItem,
        multiple: true,
      },
    ];
  }

  get dimensionItems() {
    return [
      {
        name: 'height',
        type: InputType.Decimal,
        useTextarea: true,
        label: 'article.form.height',
        rules: 'min_value:0',
      },
      { name: 'width', type: InputType.Decimal, useTextarea: true, label: 'article.form.width', rules: 'min_value:0' },
      {
        name: 'length',
        type: InputType.Decimal,
        useTextarea: true,
        label: 'article.form.length',
        rules: 'min_value:0',
      },
    ];
  }

  get weightItems() {
    return [
      {
        name: 'weight',
        type: InputType.Text,
        label: 'article.form.weight',
        rules: 'integer|min_value:0',
      },
    ];
  }

  get requirementsItems() {
    return [
      {
        name: 'min',
        type: InputType.Text,
        label: 'article.form.min',
        default: -1,
        rules: `smaller_than:@${this.$t('article.form.max')}`,
      },
      {
        name: 'max',
        type: InputType.Text,
        label: 'article.form.max',
        default: -1,
        rules: `greater_than:@${this.$t('article.form.min')}`,
      },
    ];
  }

  get tagItems() {
    return this.tags.map((t: Tag) => {
      return {
        value: t._id,
        text: this.$options.filters!.localized(t.name),
      };
    });
  }

  get constrainItems() {
    return this.constrains?.map((c: Constrain) => {
      return {
        value: c._id,
        text: this.$options.filters!.localized(c.name),
      };
    });
  }

  get printGroupsItem() {
    if (this.printGroups.length === 0) {
      return [];
    }
    return this.printGroups.map((t: PrintGroups) => {
      return {
        value: t._id,
        text: this.$options.filters!.localized(t.name),
      };
    });
  }

  public isDisabledCallback(form: { priceIsLocked: boolean }) {
    return form.priceIsLocked;
  }

  get articleItems() {
    return this.articles.map((a: Article) => {
      return {
        value: a._id,
        text: this.$options.filters!.localized(a.name),
      };
    });
  }

  get taxes() {
    return [
      { value: '0.21', text: '21%' },
      { value: '0.20', text: '20%' },
      { value: '0.19', text: '19%' },
      { value: '0.16', text: '16%' },
      { value: '0.15', text: '15%' },
      { value: '0.12', text: '12%' },
      { value: '0.10', text: '10%' },
      { value: '0.085', text: '8.5%' },
      { value: '0.081', text: '8.1%' },
      { value: '0.07', text: '7%' },
      { value: '0.077', text: '7.7%' },
      { value: '0.055', text: '5.5%' },
      { value: '0.026', text: '2.6%' },
      { value: '0.025', text: '2.5%' },
      { value: '0.021', text: '2.1%' },
      { value: '-1', text: 'default' },
    ];
  }

  get brands() {
    return [
      { value: null, text: 'None' },
      { value: ArticleRelativeBrand.SEGAFREDO, text: 'Segafredo' },
      { value: ArticleRelativeBrand.NORDSEE, text: 'Nordsee' },
      { value: ArticleRelativeBrand.COFFEE_FELLOWS, text: 'Coffee fellows' },
      { value: ArticleRelativeBrand.DALLMAYR, text: 'Dallmayr' },
    ];
  }

  get depositTypes() {
    return ['0.00', '0.08', '0.15', '0.25', '8.00'];
  }

  get fskItems() {
    return [0, 16, 18];
  }

  public serializeMeasurement(measurement: InstanceType<typeof VFormBuilder>) {
    const data = { ...measurement.form };
    if (data?.hasOwnProperty('amount')) {
      data.amount = replaceCommaWithDot(data.amount);
    }

    return data;
  }

  public getData() {
    // @ts-ignore
    const data: any = { ...this.$refs.form.form };
    // @ts-ignore
    const filesToRemove: string[] = this.$refs.form.getFilesToRemove();

    if (data.assets === null) {
      delete data.assets;
    }

    if (data.icon === null) {
      delete data.icon;
    }

    if (this.initialValues) {
      if (
        // @ts-ignore
        this.initialValues.assets &&
        // @ts-ignore
        this.initialValues.assets.thumb &&
        // @ts-ignore
        this.initialValues.assets.thumb.length > 0 &&
        filesToRemove.indexOf('assets') > -1
      ) {
        data.assets = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }

      // @ts-ignore
      if (
        // @ts-ignore
        this.initialValues.icon &&
        // @ts-ignore
        this.initialValues.icon.thumb &&
        // @ts-ignore
        this.initialValues.icon.thumb.length > 0 &&
        filesToRemove.indexOf('icon') > -1
      ) {
        data.icon = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }
    }

    const woltSettings = this.$refs?.wolt?.getData();
    if (woltSettings?.price === '') {
      delete woltSettings['price'];
    }

    const jetSettings = this.$refs?.jet?.getData();
    if (jetSettings?.price === '') {
      delete jetSettings['price'];
    }

    const uberEatsSettings = this.$refs?.uberEats?.getData();
    if (uberEatsSettings?.price === '') {
      delete uberEatsSettings['price'];
    }

    const externalOrderProviderSettings = [woltSettings, jetSettings, uberEatsSettings].filter(Boolean);

    return {
      ...data,
      ...this.$refs.nutritions.getData(),
      ...this.$refs.style.getData(),
      ...this.$refs.availableHours.getData(),
      ...this.$refs.weightDetails?.form,
      displayPrice: data.displayPrice === '' ? null : data.displayPrice,
      priceByType: {
        ...this.$refs.price.getData(),
      },
      prices: [...this.$refs.price.getPrices()],
      displayPriceByType: {
        ...this.$refs.displayPrice.getData(),
      },
      availability: {
        ...this.$refs.availability.getData(),
      },
      requirements: {
        ...this.$refs.requirements.getData(),
      },
      measurement: {
        ...this.serializeMeasurement(this.$refs.measurement),
      },
      manufacturer: { ...this.$refs.manufacturer.getData() },
      compound: {
        ...this.$refs.compound.getData(),
      },
      dimensions: { ...this.$refs.dimension?.form },
      externalOrderProviderSettings,
    };
  }
}
